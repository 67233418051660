import React, { useEffect, useState } from 'react';
import { Drawer, Table, } from 'antd';
import axios from 'axios';
import moment from 'moment/moment';
import { formatNumberEuro } from '../../utils';

export default function AgentContributionsFormDrawer({ agent, visible, onClose }) {
  if (!agent) return null;
  const [contributions, setContributions] = useState([]);


  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const contributions = await axios(`/api/agents/${agent._id}/contributions`);
        setContributions(contributions.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (visible && agent._id) fetchContributions();
  }, [visible, agent]);

  const close = () => {
    onClose();
  }

  return (
    <>
      <Drawer
        width={1000}
        title={`Total contributions for ${agent?.name}: ${formatNumberEuro(agent.accumulated)}`}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
      >
        <Table
          columns={[
            {
              title: 'Transaction',
              dataIndex: 'transaction_number',
              key: 'transaction_number',
              sorter: (a, b) => a.transaction_number - b.transaction_number,
              width: 50
            },
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
              render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
              sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
              responsive: ['md','lg', 'xl', 'xxl'],
              width: 120,
            },
            {
              title: 'Property',
              dataIndex: 'imobil',
              key: 'imobil',
              sorter: (a, b) => a.imobil.localeCompare(b.imobil, undefined, { sensitivity: 'base' }),
              responsive: ['md','lg', 'xl', 'xxl']
            },
            {
              title: 'Agt Fee',
              key: 'agentFee',
              render: (text, record) => `${record.agentFees?.percentage}%`,
              sorter: (a, b) => a.agentFees?.percentage - b.agentFees?.percentage,
              width: 100,
              align: 'right'
            },
            {
              title: 'Tot Ref %',
              key: 'totalRef',
              render: (text, record) => `${record.referrals?.reduce((acc, val) => (acc + val.percentage), 0)}%`,
              sorter: (a, b) => a.agentFees?.percentage - b.agentFees?.percentage,
              width: 100,
              align: 'right'
            },
            {
              title: 'Ctr value',
              key: 'agentFee',
              render: (text, record) => formatNumberEuro(record.contracts?.totalValue),
              sorter: (a, b) => a.contracts?.totalValue - b.contracts?.totalValue,
              width: 100,
              align: 'right'
            },
            {
              title: 'Net contrib',
              key: 'netContrib',
              render: (text, record) => {
                const totalRef = record.referrals?.reduce((acc, val) => (acc + val.percentage), 0);
                const netContractValue = (record.contracts?.totalValue * (100 - totalRef)) / 100;
                const netContrib =  (netContractValue * record.agentFees?.percentage) / 100;
                return formatNumberEuro(netContrib);
              },
              width: 100,
              align: 'right'
            },
          ]}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={6}>TOTAL</Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                <strong>{formatNumberEuro(agent.accumulated)}</strong>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
          dataSource={contributions[0]?.transactions}
          size="middle"
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </Drawer>
    </>
  );
}
