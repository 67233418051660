import React, { Component } from 'react';
import axios from 'axios';
import {
  Table,
  Alert,
  Space,
  Button,
  PageHeader,
  Tag,
  Breadcrumb, Input, Tooltip, Popconfirm,
} from 'antd';
import {
  AppstoreOutlined, AuditOutlined, DeleteOutlined, FileAddOutlined, FilePdfOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import moment from 'moment';
import ContractInvoiceFormDrawer from './ContractInvoiceFormDrawer';
import TransactionContractFormDrawer from '../transaction/TransactionContractFormDrawer';
import UserProvider from '../../context/UserContext';

export default class ContractList extends Component {
  static contextType = UserProvider.context;

  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
      modalVisible: false,
      invoiceModalVisible: false,
      idToEdit: null,
      contractForInvoice: null,
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => {
      return Object.keys(o).some((k) => {
        return String(o[k]).toLowerCase().includes(value.toLowerCase())
          // search in the property as well
          || String(o[k]?.imobil).toLowerCase().includes(value.toLowerCase())
      });
    });

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    try {
      const contracts = await axios('/api/contracts/');
      this.setState({ baseData: contracts.data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  removeObject = async (id) => {
    try {
      await axios.delete(`/api/contracts/${id}`);
      const contracts = await axios('/api/contracts/');
      this.setState({ baseData: contracts.data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  render() {
    const {
      error, baseData, filteredData, selectedRowKeys, modalVisible, idToEdit, invoiceModalVisible, contractForInvoice
    } = this.state;
    if (!baseData) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Number',
        dataIndex: 'contract_number',
        key: 'contract_number',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              this.setState({idToEdit: record._id, modalVisible: true});
            }}
          >
            {record.contract_number}
          </Button>
        ),
        sorter: (a, b) => a.contract_number - b.contract_number,
        width: 100,
      },
      {
        title: 'Transaction',
        dataIndex: 'transaction',
        key: 'transaction',
        render: (text, record) => record.transaction.transaction_number,
        sorter: (a, b) => a.transaction.transaction_number - b.transaction.transaction_number,
        width: 100,
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Property',
        key: 'property',
        render: (text, record) => record.transaction.imobil,
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        width: 120,
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        sorter: (a, b) => a.client.localeCompare(b.client, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Fee',
        dataIndex: 'feeValue',
        key: 'feeValue',
        render: (text, record) => `€${record.feeValue.toLocaleString('en-US')}`,
        sorter: (a, b) => a.feeValue - b.feeValue,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Trans%Fee',
        key: 'transactionFeeValue',
        render: (text, record) => {
          const { transaction } = record;
          const totalTransactionFee = (transaction.buyerFee || 0) + (transaction.sellerFee || 0);
          if (totalTransactionFee > 0) return (100 * record.feeValue/totalTransactionFee).toFixed(2);
          return '';
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: '% Inv.',
        key: 'prcinv',
        render: (text, record) => {
          const { feeValue } = record;
          const totalInvoicesFee = record.invoices?.totalInvoicesValue || 0;
          if (!feeValue || !totalInvoicesFee) return '';
          return `${(100 * totalInvoicesFee/feeValue).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right'
      },
      {
        title: '% Cashed',
        key: 'prccash',
        render: (text, record) => {
          const { feeValue } = record;
          const totalInvoicesCashed = record.invoices?.totalInvoicesCashed || 0;
          if (!feeValue || !totalInvoicesCashed) return '';
          return `${(100 * totalInvoicesCashed/feeValue).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right'
      },
      {
        title: 'Closed',
        dataIndex: 'closed',
        key: 'status',
        render: (text, record) => {
          return <Tag color={record.closed ? 'volcano' : 'green'}>
            {record.closed ? 'YES' : 'NO'}
          </Tag>
        },
        filters: [
          {
            text: 'No',
            value: false,
          },
          {
            text: 'Yes',
            value: true,
          },
        ],
        filterSearch: false,
        onFilter: (value, record) => record.closed === value,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'center'
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Add invoice (only for non-project contracts)">
              <Button
                shape="circle" key="addInvoice" icon={<FileAddOutlined />}
                onClick={() => {
                  this.setState({contractForInvoice: record, invoiceModalVisible: true});
                }}
                disabled={record.closed || record.transaction?.project}
              />
            </Tooltip>
            <Tooltip title="See attachment">
              <Button
                disabled={!record.attachmentFileName}
                shape="circle" size="small"
                href={`/uploads/${record.attachmentFileName}`}
                icon={<FilePdfOutlined />}
                target="_blank"
              />
            </Tooltip>
            {
              this.context?.userData?.level === 2 && (
                <Popconfirm
                  title="Are you sure to delete this contract?"
                  onConfirm={() => this.removeObject(record._id)}
                  onCancel={null}
                  okText="YES"
                  cancelText="No"
                  placement="left"
                  disabled={!!record.invoices}
                >
                  <Tooltip title="Delete contract">
                    <Button
                      disabled={!!record.invoices}
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )
            }
          </Space>
        ),
        responsive: ['lg', 'xl', 'xxl'],
        width: 120,
        align: 'center'
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <AuditOutlined />
            {' '}
            Contract list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title="Contract List"
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} contract(s) selected`}</Tag> : null),
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"

          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
        <TransactionContractFormDrawer
          id={idToEdit}
          onClose={async () => {
            this.setState({modalVisible: false, idToEdit: null});
            await this.loadData();
          }}
          visible={modalVisible}
        />
        <ContractInvoiceFormDrawer
          contract={contractForInvoice}
          onClose={async () => {
            this.setState({invoiceModalVisible: false, contractForInvoice: null});
            await this.loadData();
          }}
          visible={invoiceModalVisible}
        />
      </>
    );
  }
}
