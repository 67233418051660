import React from 'react';
import axios from 'axios';
import { Alert, Button, Card, Form, Input, message, Select } from 'antd';

const Register = (props) => {
  // const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      await axios.post('/api/users/signup', values);
      message.success('Account created!');
      // navigate(state?.path || "/");
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <>
      <Card style={{ textAlign: "center", width: "50%", margin: "96px auto" }}>
        <Alert
          style={{ textAlign: "left" }}
          showIcon
          message="Welcome to BrokerPlan!"
          description="After registration, you must wait for an admin to activate your account (assign a level) in order to login and use the application."
          type="info"
        />
        <Form
          style={{ marginTop: '24px' }}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 8,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Department" name="department" style={{ textAlign: "left" }}>
            <Select>
              <Option value="rezi">rezi</Option>
              <Option value="office">office</Option>
              <Option value="industrial">industrial</Option>
              <Option value="land">land</Option>
              <Option value="PM">PM</Option>
              <Option value="research">research</Option>
              <Option value="investment">investment</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Status" name="status" style={{ textAlign: "left" }}>
            <Select>
              <Select.Option value="JUNIOR">Account junior</Select.Option>
              <Select.Option value="DIRECTOR">Associate director</Select.Option>
              <Select.Option value="EQUITY">Managing equity partner</Select.Option>
              <Select.Option value="PARTNER">Partner</Select.Option>
              <Select.Option value="SALES">Sales manager</Select.Option>
              <Select.Option value="ACCOUNT">Senior account manager</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 8,
            }}
          >
            <Button type="primary" htmlType="submit">
              REGISTER
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default Register;
