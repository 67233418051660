import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Breadcrumb, Button, Input, PageHeader, Table, Tag, } from 'antd';
import { ApartmentOutlined, AppstoreOutlined, AuditOutlined, FileAddOutlined, } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import moment from 'moment';
import withRouter from '../../Routes/withRouter';
import ProjectInvoiceFormDrawer from './ProjectInvoiceFormDrawer';

class ProjectContractList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      project: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
      invoiceModalVisible: false,
      contractForInvoice: null,
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => {
      return Object.keys(o).some((k) => {
        return String(o[k]).toLowerCase().includes(value.toLowerCase())
          // search in the property as well
          || String(o[k]?.imobil).toLowerCase().includes(value.toLowerCase())
      });
    });

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { id } = this.props.params;
    try {
      const contracts = await axios(`/api/projects/${id}/contracts/`);
      const project = await axios(`/api/projects/${id}`);
      this.setState({ baseData: contracts.data, project: project.data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  render() {
    const {
      error, baseData, project, filteredData, selectedRowKeys, invoiceModalVisible, contractForInvoice
    } = this.state;
    if (!baseData || !project) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: (record) => {
        return {
          disabled: record.invoices?.totalInvoicesValue === record.feeValue
        };
      }
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Number',
        dataIndex: 'contract_number',
        key: 'contract_number',
        // render: (text, record) => (
        //   <Button
        //     type="link"
        //     onClick={() => {
        //       this.setState({idToEdit: record._id, modalVisible: true});
        //     }}
        //   >
        //     {record.contract_number}
        //   </Button>
        // ),
        sorter: (a, b) => a.contract_number - b.contract_number,
        width: 100,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        width: 120,
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        sorter: (a, b) => a.client.localeCompare(b.client, undefined, { sensitivity: 'base' }),
        width: 300,
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Comment',
        key: 'comment',
        render: (text, record) => record.transaction.comment,
        sorter: (a, b) => a.transaction.comment.localeCompare(b.transaction.comment, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Fee',
        dataIndex: 'feeValue',
        key: 'feeValue',
        render: (text, record) => `€${record.feeValue.toLocaleString('en-US')}`,
        sorter: (a, b) => a.feeValue - b.feeValue,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: '% Inv.',
        key: 'prcinv',
        render: (text, record) => {
          const { feeValue } = record;
          const totalInvoicesFee = record.invoices?.totalInvoicesValue || 0;
          if (!feeValue || !totalInvoicesFee) return '';
          return `${(100 * totalInvoicesFee/feeValue).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right'
      },
      {
        title: '% Cashed',
        key: 'prccash',
        render: (text, record) => {
          const { feeValue } = record;
          const totalInvoicesCashed = record.invoices?.totalInvoicesCashed || 0;
          if (!feeValue || !totalInvoicesCashed) return '';
          return `${(100 * totalInvoicesCashed/feeValue).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right'
      },
      {
        title: 'Closed',
        dataIndex: 'closed',
        key: 'status',
        render: (text, record) => {
          return <Tag color={record.closed ? 'volcano' : 'green'}>
            {record.closed ? 'YES' : 'NO'}
          </Tag>
        },
        filters: [
          {
            text: 'No',
            value: false,
          },
          {
            text: 'Yes',
            value: true,
          },
        ],
        filterSearch: false,
        onFilter: (value, record) => record.closed === value,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'center'
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/projects">
              <ApartmentOutlined />
              {' '}
              Project list
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <AuditOutlined />
            {' '}
            Contract list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={`Contract List for project: ${project.client}`}
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} contract(s) selected`}</Tag> : null),
            <Button
              key="1"
              type="primary"
              icon={<FileAddOutlined />}
              disabled={!selectedRowKeys?.length}
              onClick={() => {
                this.setState({ invoiceModalVisible: true });
              }}
            >
              Add Invoices
            </Button>,
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"

          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
        <ProjectInvoiceFormDrawer
          contracts={tableData.filter(item => selectedRowKeys.includes(item._id))}
          project={project}
          onClose={async () => {
            this.setState({invoiceModalVisible: false});
            await this.loadData();
          }}
          visible={invoiceModalVisible}
        />
      </>
    );
  }
}

export default withRouter(ProjectContractList);
