import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Statistic, Typography, } from 'antd';
import axios from 'axios';
import { AuditOutlined, SwapOutlined } from '@ant-design/icons';

const { Title } = Typography;

export default function Home() {
  const [transactionCounts, setTransactionCounts] = useState(null);
  const [contractCounts, setContractCounts] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const counts = await axios(`/api/transactions/counts`);
        const counts2 = await axios(`/api/contracts/counts`);
        setTransactionCounts(counts.data[0]);
        setContractCounts(counts2.data[0]);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCounts();
  }, []);

  return (
    <div style={{ marginTop: '32px' }}>
      <Typography>
        <Title>
          BrokerPlan by CrossPoint
        </Title>
      </Typography>
      <Divider/>
      <Row gutter={[16, 24]}>
        <Col span={8}>
          <Card>
            <Statistic
              title="Transactions COUNT"
              value={transactionCounts?.count}
              prefix={<SwapOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total transactions VALUE"
              value={transactionCounts?.value}
              precision={2}
              suffix="€"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total transactions FEE"
              value={transactionCounts?.fee}
              precision={2}
              suffix="€"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Contracts COUNT"
              value={contractCounts?.count}
              prefix={<AuditOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total contracts FEE"
              value={contractCounts?.totalFee}
              precision={2}
              suffix="€"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total contracts INVOICED"
              value={contractCounts?.totalInvoiced}
              precision={2}
              suffix="€"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Total contracts CASHED"
              value={contractCounts?.totalCashed}
              precision={2}
              suffix="€"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
