import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Form, Input, Button, message, Select, Space, Drawer, InputNumber, DatePicker, Divider, Typography, Row, Col,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formatNumberEuro } from '../../utils';

const { Option } = Select;
const { TextArea } = Input;

const getTotalPercentage = (referrals) => referrals?.reduce((sum, current) => sum + current?.percentage, 0) || 0;

export default function ProjectFormDrawer({ id, visible, onClose }) {
  const [form] = Form.useForm();
  const inputRef = useRef();
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    if (!visible) return;
    const fetchProject = async () => {
      try {
        const result = await axios(`/api/projects/${id}`);
        form.setFieldsValue(result.data);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchAgents = async () => {
      try {
        const result = await axios('/api/agents');
        setAgents(result.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAgents();
    if (id) fetchProject();
  }, [id, inputRef, visible]);

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef.current.focus();
    },
    [inputRef],
  );

  const close = () => {
    form.resetFields();
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      const totalAgentsPrc = getTotalPercentage(values.agentFees);
      if (totalAgentsPrc !== 100) {
        message.error("AGENTS FEES should be 100% !!!");
        return;
      }
      if (values.sellers?.replace) values.sellers = values.sellers.replace(/\s/g, '').split(',');
      await axios({
        method: id ? 'PUT' : 'POST',
        url: `/api/projects/${id ?? ''}`,
        data: values,
      });
      message.success('Project saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  return (
    <>
      <Drawer
        width={800}
        title={id ? "Edit Project" : "Add Project"}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Form
          form={form} layout="vertical" size="default" name="group-form"
          initialValues={{ buyerFee: 0, sellerFee: 0, buyerFeePercentage: 0, sellerFeePercentage: 0 }}
        >
          <Row gutter={[16,0]}>
            <Col span={24}>
              <Form.Item
                name="client"
                label="Client"
                rules={[{ required: true }]}
              >
                <Input ref={inputRef}  />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16,0]}>
            <Col span={12}>
              <Form.Item label="Department" name="department" rules={[{ required: true }]}>
                <Select>
                  <Option value="rezi">rezi</Option>
                  <Option value="office">office</Option>
                  <Option value="industrial">industrial</Option>
                  <Option value="land">land</Option>
                  <Option value="PM">PM</Option>
                  <Option value="research">research</Option>
                  <Option value="investment">investment</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Seller" name="sellers">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16,0]}>
            <Col span={12}>
              <Form.Item label="Buyer Fee" name="buyerFeePercentage">
                <InputNumber addonBefore="%" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Seller Fee" name="sellerFeePercentage">
                <InputNumber addonBefore="%" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Typography.Title level={4}>Agents (should equal 100% in total!)</Typography.Title>
          <Form.List name="agentFees">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="middle">
                    <Form.Item
                      {...restField}
                      name={[name, 'agent']}
                      rules={[{ required: true, message: 'Missing agent' }]}
                    >
                      <Select style={{ width: 200 }}>
                        <Select.Option value="" />
                        {
                          agents.map((obj) => <Select.Option value={obj._id} key={obj._id}>{obj.name}</Select.Option>)
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'percentage']}
                      rules={[{ required: true, message: 'Missing agent percentage' }]}
                    >
                      <InputNumber addonBefore="%" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add agent
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
}
