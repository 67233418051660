import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Space,
  Typography,
  Upload,
} from 'antd';
import axios from 'axios';
import moment from 'moment/moment';
import { UploadOutlined } from '@ant-design/icons';
import { formatNumberEuro } from '../../utils';

export default function TransactionContractFormDrawer({ id, transaction, visible, onClose }) {
  const [form] = Form.useForm();
  const [contractNumber, setContractNumber] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [contractTransaction, setContractTransaction] = useState(null);
  const inputRef = useRef();
  const feeValue = Form.useWatch('feeValue', form);

  useEffect(() => {
    if (!visible) return;
    const fetchContract = async () => {
      try {
        const result = await axios(`/api/contracts/${id}`);
        const contract = result.data;
        contract.date = moment(contract.date);
        setContractNumber(contract.contract_number);
        await fetchContracts(contract.transaction, contract);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchContracts = async (tr, contract) => {
      try {
        setContractTransaction(tr);
        const result = await axios(`/api/transactions/${tr._id}/contracts`);
        setContracts(result.data);
        if (contract) form.setFieldsValue(contract);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchProject = async (tr) => {
      try {
        const result = await axios(`/api/projects/${tr.project}`);
        if (result.data) form.setFieldsValue(result.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (id) {
      fetchContract();
    }
    else if(transaction) {
      fetchContracts(transaction);
      fetchProject(transaction);
    }
  }, [inputRef, id, transaction, visible]);

  const computedData = useMemo(
    () => {
      let max;
      let totalContracted = 0;
      let totalTransactionFee = 0;
      if (contracts && contractTransaction) {
        totalContracted = contracts.reduce( ( sum, { feeValue, _id } ) => {
          if (!id || id !== _id) return sum + feeValue;
          return sum;
        } , 0);
        totalTransactionFee = contractTransaction?.totalFee || 0;
        if (totalTransactionFee > 0) max = totalTransactionFee - totalContracted;
      }
      if (!id) form.setFieldsValue({ feeValue: max });
      return { max, totalContracted, totalTransactionFee };
    },
    [id, visible, contracts, contractTransaction]
  );

  const remainingFee = useMemo(
    () => computedData.max - feeValue,
    [feeValue, computedData]
  );

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef?.current?.focus();
    },
    [inputRef],
  );

  const close = () => {
    form.resetFields();
    setContracts([]);
    setContractTransaction(null);
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      const data = new FormData();
      const file = values.attachment?.file;
      if (file) {
        data.append("attachment", values.attachment.file.originFileObj);
        data.append("attachmentFileName", values.attachment.file.name);
      }
      data.append("transaction", contractTransaction._id);
      data.append("date", values.date);
      data.append("client", values.client);
      data.append("code", values.code);
      data.append("feeValue", values.feeValue);
      if (values.valueRON) data.append("valueRON", values.valueRON);
      await axios({
        method: id ? 'PUT' : 'POST',
        url: `/api/contracts/${id ?? ''}`,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success('Contract saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before transaction date
    return current && current < moment(contractTransaction.date).startOf('day');
  };

  return (
    <>
      <Drawer
        width={640}
        title={(id && contractNumber) ? `Edit Contract: ${contractNumber}` : "Add Contract"}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Typography.Title level={5}>Transaction: {contractTransaction?.transaction_number} - {contractTransaction?.imobil}</Typography.Title>
        {
          computedData && <>
            <Typography.Paragraph>
              Already contracted (not counting current contract): {formatNumberEuro(computedData.totalContracted)} of {formatNumberEuro(computedData.totalTransactionFee)}.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Maximum fee to be submitted by current contract: {formatNumberEuro(computedData.max)}.</strong>
            </Typography.Paragraph>
          </>
        }
        <Divider />
        {
          computedData?.max !== undefined && (
            <Form
              form={form}
              layout="vertical"
              size="default"
              name="contract-form"
            >
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true }]}
              >
                <DatePicker ref={inputRef} disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item label="Client" name="client" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Code" name="code" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Fee"
                name="feeValue"
                rules={[{
                  required: true,
                  message: 'Fee is required and should be lower than maximum value!',
                }]}
              >
                <InputNumber
                  addonBefore="EUR"
                  min={1}
                  max={computedData.max === 0 ? null : computedData.max}
                  placeholder={`Min 1 - Max ${computedData.max || ''}`}
                />
              </Form.Item>
              <>
                {
                  computedData.max &&
                  <div style={{ marginBottom: '8px' }}>
                    <Typography>
                      <pre>Remaining to contract after this: {formatNumberEuro(remainingFee)} ({(remainingFee / computedData.max * 100).toFixed(2)}%)</pre>
                    </Typography>
                  </div>
                }
              </>
              <Divider/>
              <Form.Item label="File attachment (PDF only)" name="attachment">
                <Upload
                  customRequest={() => true}
                  showUploadList={true}
                  maxCount={1}
                  accept="application/pdf"
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Form>
          )
        }
            <Divider />
            <List
              header={<Typography.Title level={5}>{`Existing contracts for transaction ${contractTransaction?.transaction_number}`}</Typography.Title>}
              itemLayout="horizontal"
              dataSource={contracts || []}
              renderItem={item => (
                <List.Item
                  // actions={[<Button shape="circle" icon={<DeleteOutlined />} key={`${item._id}delete`} onClick={async () => {
                  //   await axios.delete(`/api/contracts/${item._id}`);
                  //   await fetchContracts();
                  // }} />]}
                >
                  <List.Item.Meta
                    title={`Contract ${item.contract_number} = ${formatNumberEuro(item.feeValue)}`}
                  />
                </List.Item>
              )}
            />
      </Drawer>
    </>
  );
}
