import React, { useCallback, useRef } from 'react';
import { Button, DatePicker, Divider, Drawer, Form, Input, List, message, Space, Typography, } from 'antd';
import axios from 'axios';
import { formatNumberEuro } from '../../utils';

export default function ProjectInvoiceFormDrawer({ contracts, project, visible, onClose }) {
  if (!contracts) return null;
  const [form] = Form.useForm();
  const inputRef = useRef();

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef?.current?.focus();
    },
    [inputRef],
  );

  const close = () => {
    form.resetFields();
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      await Promise.all(contracts.map((item) => axios({
          method: 'POST',
          url: `/api/invoices`,
          data: {
            contract: item._id,
            value: item.feeValue - (item.invoices?.totalInvoicesValue || 0),
            ...values
          },
        })
      ));
      message.success('Invoice saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  return (
    <>
      <Drawer
        width={640}
        title={`Invoice all selected contracts from project ${project?.client}`}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Form form={form} layout="vertical" size="default" name="contract-form">
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: 'Please input the date!' }]}
          >
            <DatePicker ref={inputRef}/>
          </Form.Item>
          <Form.Item
            label="Code" name="code"
            rules={[{ required: true, message: 'Please input the code!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
        <Divider />
        <List
          header={<Typography.Title level={5}>{`Amounts to be invoiced for each contract`}</Typography.Title>}
          itemLayout="horizontal"
          dataSource={contracts}
          renderItem={item => (
            <List.Item>
              Contract { item.contract_number }: { formatNumberEuro(item.feeValue - (item.invoices?.totalInvoicesValue || 0)) }
            </List.Item>
          )}
        />
        <Divider />
        <Typography><pre>TOTAL: {
          formatNumberEuro(contracts.reduce((sum, item) => (sum + (item.feeValue - (item.invoices?.totalInvoicesValue || 0))), 0))
        }</pre></Typography>
      </Drawer>
    </>
  );
}
