import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Breadcrumb, Button, Input, message, PageHeader, Popconfirm, Space, Table, Tag, Tooltip, } from 'antd';
import {
  AppstoreOutlined,
  DeleteOutlined,
  EuroCircleOutlined,
  FilePdfOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import moment from 'moment';
import CashInvoiceFormDrawer from './CashInvoiceFormDrawer';
import ContractInvoiceFormDrawer from './ContractInvoiceFormDrawer';
import UserProvider from '../../context/UserContext';

export default class ContractInvoiceList extends Component {
  static contextType = UserProvider.context;

  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
      cashModalVisible: false,
      invoiceToCash: null,
      invoiceModalVisible: false,
      invoiceToEdit: null,
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => {
      return Object.keys(o).some((k) => {
        return String(o[k]).toLowerCase().includes(value.toLowerCase())
          // search in the property as well
          || String(o[k]?.transaction?.imobil).toLowerCase().includes(value.toLowerCase())
      });
    });

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    try {
      const invoices = await axios('/api/invoices/');
      this.setState({ baseData: invoices.data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  render() {
    const {
      error, baseData, filteredData, selectedRowKeys,
      cashModalVisible, invoiceToCash, invoiceToEdit, invoiceModalVisible,
    } = this.state;
    if (!baseData) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Invoice #',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              this.setState({invoiceToEdit: record, invoiceModalVisible: true});
            }}
          >
            {record.invoice_number}
          </Button>
        ),
        sorter: (a, b) => a.invoice_number - b.invoice_number,
        width: 120,
      },
      {
        title: 'Contract #',
        dataIndex: 'contract',
        key: 'contract',
        render: (text, record) => record.contract?.contract_number,
        sorter: (a, b) => a.contract?.contract_number - b.contract?.contract_number,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
      },
      {
        title: 'Property',
        key: 'contract',
        render: (text, record) => record.contract?.transaction?.imobil,
        sorter: (a, b) => a.contract?.transaction?.imobil.localeCompare(b.contract?.transaction?.imobil),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 150,
      },
      {
        title: 'Value',
        dataIndex: 'value',
        render: (text, record) => `€${record.value?.toLocaleString('en-US') || ''}`,
        key: 'value',
        sorter: (a, b) => a.value - b.value,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Cashed',
        dataIndex: 'cashedAmount',
        key: 'cashed',
        render: (text, record) => `€${record.cashedAmount?.toLocaleString('en-US') || ''}`,
        sorter: (a, b) => a.cashedAmount - b.cashedAmount,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right',
        filters: [
          {
            text: 'Partially',
            value: false,
          },
          {
            text: 'Fully',
            value: true,
          },
        ],
        filterSearch: false,
        onFilter: (value, record) => {
          if (value) {
            return record.cashedAmount === record.value;
          }
          return record.cashedAmount !== record.value;
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Cash invoice">
              <Button
                shape="circle" key="cashInvoice" icon={<EuroCircleOutlined />}
                onClick={() => this.setState({cashModalVisible: true, invoiceToCash: record})}
                disabled={!!record.cashed}
              />
            </Tooltip>
            <Tooltip title="See attachment">
              <Button
                disabled={!record.attachmentFileName}
                shape="circle" size="small"
                href={`/uploads/${record.attachmentFileName}`}
                icon={<FilePdfOutlined />}
                target="_blank"
              />
            </Tooltip>
            {
              this.context?.userData?.level === 2 && (
                <Popconfirm
                  title="Are you sure to delete this invoice?"
                  onConfirm={async () => {
                    await axios.delete(`/api/invoices/${record._id}`);
                    await this.loadData();
                  }}
                  onCancel={null}
                  okText="YES"
                  cancelText="No"
                  placement="left"
                  disabled={!!record.cashedAmount}
                >
                  <Tooltip title="Delete invoice">
                    <Button
                      shape="circle" key="deleteInvoice" icon={<DeleteOutlined />}
                      disabled={!!record.cashedAmount}
                    />
                  </Tooltip>
                </Popconfirm>
              )
            }
          </Space>
        ),
        responsive: ['lg', 'xl', 'xxl'],
        width: 120,
        align: 'center'
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <SnippetsOutlined />
            {' '}
            Invoice list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title="Invoice List"
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} invoice(s) selected`}</Tag> : null),
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"

          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
        <CashInvoiceFormDrawer
          invoice={invoiceToCash}
          onClose={async () => {
            this.setState({cashModalVisible: false, invoiceToCash: null});
            await this.loadData();
          }}
          visible={cashModalVisible}
        />
        <ContractInvoiceFormDrawer
          contract={invoiceToEdit?.contract}
          invoice={invoiceToEdit}
          onClose={async () => {
            this.setState({invoiceModalVisible: false, invoiceToEdit: null});
            await this.loadData();
          }}
          visible={invoiceModalVisible}
        />
      </>
    );
  }
}
