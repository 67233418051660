import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Breadcrumb, Button, Input, PageHeader, Space, Table, Tag, Tooltip, } from 'antd';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  FileAddOutlined,
  SwapOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import ProjectFormDrawer from './ProjectFormDrawer';
import withNavigate from '../../Routes/withNavigate';

class ProjectList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
      modalVisible: false,
      idToEdit: null,
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => Object.keys(o).some((k) => String(o[k])
      .toLowerCase()
      .includes(value.toLowerCase())
        // search in the away group's name as well
        || String(o[k]?.name)
          .toLowerCase()
          .includes(value.toLowerCase())));

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    try {
      const projects = await axios('/api/projects/');
      const baseData = projects.data;
      this.setState({ baseData });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  removeObject = async (id) => {
    try {
      await axios.delete(`/api/projects/${id}`);
      await this.loadData();
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  render() {
    const {
      error, baseData, filteredData, selectedRowKeys, modalVisible, idToEdit
    } = this.state;
    if (!baseData) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              this.setState({idToEdit: record._id, modalVisible: true});
            }}
          >
            {record.client}
          </Button>
        ),
        sorter: (a, b) => a.client.localeCompare(b.client, undefined, { sensitivity: 'base' }),
        width: 400
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        sorter: (a, b) => a.department.localeCompare(b.department, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Seller',
        dataIndex: 'sellers',
        key: 'sellers',
        render: (text, record) => record.sellers.join(", "),
        sorter: (a, b) => a.sellers.join('').localeCompare(b.sellers.join(''), undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Total Fee',
        key: 'fee',
        render: (text, record) => `€${record.totalFee?.toLocaleString('en-US') || 0}`,
        sorter: (a, b) => a.totalFee - b.totalFee,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="View Contracts">
              <Button shape="circle" icon={<UnorderedListOutlined />} onClick={() => {
                this.props.navigate(`/projects/${record._id}/contracts`);
              }} />
            </Tooltip>
            <Tooltip title="View Invoices">
              <Button shape="circle" icon={<DatabaseOutlined />} onClick={() => {
                this.props.navigate(`/projects/${record._id}/invoices`);
              }} />
            </Tooltip>
          </Space>
        ),
        responsive: ['lg', 'xl', 'xxl'],
        width: 120,
        align: 'center',
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ApartmentOutlined />
            {' '}
            Project list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title="Project List"
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} project(s) selected`}</Tag> : null),
            <Button
              key="1"
              type="primary"
              icon={<FileAddOutlined />}
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
            >
              Add Project
            </Button>,
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"

          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
        <ProjectFormDrawer
          id={idToEdit}
          onClose={async () => {
            this.setState({modalVisible: false, idToEdit: null});
            await this.loadData();
          }}
          visible={modalVisible}
        />
      </>
    );
  }
}

export default withNavigate(ProjectList);
