import React, { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import UserProvider from '../context/UserContext';

export default function RequireAuth({ children }) {
  const { userData, initDone } = useContext(UserProvider.context);
  const location = useLocation();

  if (!initDone) return null;
  return userData ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
