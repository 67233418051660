import React, { useCallback, useContext, useEffect, useRef } from 'react';
import {
  Form, Input, Button, message, Select, Space, Drawer, InputNumber,
} from 'antd';
import axios from 'axios';
import UserProvider from '../../context/UserContext';

const layout = {
  wrapperCol: { span: 24 },
};

export default function AgentFormDrawer({ id, visible, onClose }) {
  const [form] = Form.useForm();
  const inputRef = useRef();
  const { userData } = useContext(UserProvider.context);

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const result = await axios(`/api/agents/${id}`);
        const agent = result.data;
        form.setFieldsValue(agent);
      } catch (e) {
        console.log(e);
      }
    };
    if (visible && id) fetchAgent();
  }, [id, visible, inputRef]);

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef?.current?.focus();
    },
    [inputRef],
  );

  const close = () => {
    form.resetFields();
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      await axios({
        method: id ? 'PUT' : 'POST',
        url: `/api/agents/${id ?? ''}`,
        data: values,
      });
      message.success('Agent saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  return (
    <>
      <Drawer
        width={640}
        title={id ? "Edit Agent" : "Add Agent"}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Form form={form} layout="vertical" size="default" name="group-form">
          <Form.Item
            {...layout}
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input ref={inputRef}  />
          </Form.Item>
          <Form.Item
            {...layout}
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: 'email'
              },
            ]}
          >
            <Input disabled={userData.level !== 2} />
          </Form.Item>
          <Form.Item label="Level" name="level">
            <Select disabled={userData.level !== 2}
              options={[
                { label: 'Inactive', value: -1 },
                { label: 'Agent', value: 0 },
                { label: 'Dept.Head', value: 1 },
                { label: 'ADMIN', value: 2 },
              ]}
            />
          </Form.Item>
          <Form.Item label="Department" name="department" rules={[{ required: true }]}>
            <Select disabled={userData.level !== 2} >
              <Option value="rezi">rezi</Option>
              <Option value="office">office</Option>
              <Option value="industrial">industrial</Option>
              <Option value="land">land</Option>
              <Option value="PM">PM</Option>
              <Option value="research">research</Option>
              <Option value="investment">investment</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Status" name="status">
            <Select>
              <Select.Option value="JUNIOR">Account junior</Select.Option>
              <Select.Option value="DIRECTOR">Associate director</Select.Option>
              <Select.Option value="EQUITY">Managing equity partner</Select.Option>
              <Select.Option value="PARTNER">Partner</Select.Option>
              <Select.Option value="SALES">Sales manager</Select.Option>
              <Select.Option value="ACCOUNT">Senior account manager</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
