import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Descriptions, Layout, Menu, message, notification, Row } from 'antd';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  IdcardOutlined,
  SnippetsOutlined,
  SwapOutlined,
  UserOutlined
} from '@ant-design/icons';
import { ReactComponent as Logo } from '../../images/logo.svg';
import axios from 'axios';
import UserProvider from '../../context/UserContext';

const { Header } = Layout;
const { SubMenu } = Menu;

function Home() {
  const { setUserData, userData } = useContext(UserProvider.context);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const user = await axios.get('/api/users/logout');
      message.success(`Logout ok. Bye, ${userData.firstName}!`);
      setUserData(null);
      navigate("/login");
    } catch (err) {
      message.error(err.message);
    }
  }

  return (
    <Header className="site-layout-header">
      <Row wrap={false} align="middle" justify="space-between">
        <Col xs={22} sm={22} md={6} lg={6} xl={6}>
          <Link to="/"><Logo style={{ width: "125px", display: "inline-block" }} /></Link>
        </Col>
        {
          userData &&
          <Col xs={2} sm={2} md={18} lg={18} xl={18} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Menu mode="horizontal" defaultSelectedKeys={['1']}>
              <Menu.Item key="1" icon={<AppstoreOutlined/>}>
                <Link to="/" className="NavBar-Link">Home</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<ApartmentOutlined />}>
                <Link to="/projects" className="NavBar-Link">Projects</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<SwapOutlined />}>
                <Link to="/transactions" className="NavBar-Link">Transactions</Link>
              </Menu.Item>
              <Menu.Item key="4" icon={<AuditOutlined />}>
                <Link to="/contracts" className="NavBar-Link">Contracts</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<SnippetsOutlined />}>
                <Link to="/invoices" className="NavBar-Link">Invoices</Link>
              </Menu.Item>
              {
                !!userData?.level && <Menu.Item key="6" icon={<IdcardOutlined />}>
                  <Link to="/agents" className="NavBar-Link">Agents</Link>
                </Menu.Item>
              }
              <SubMenu key="SubMenu" icon={<UserOutlined/>} title={userData.name}>
                <Menu.Item key="profile" onClick={() => {
                  notification.open({
                    message: 'User Info',
                    description: <Descriptions bordered layout="vertical" size="small">
                      <Descriptions.Item label="Name"><strong>{userData.name}</strong></Descriptions.Item>
                      <Descriptions.Item label="Email">{userData.email}</Descriptions.Item>
                      <Descriptions.Item label="Level">
                        { userData.level === 2 ? 'ADMIN' : userData.level === 1 ? 'Head of Department' : 'Agent' }
                      </Descriptions.Item>
                      <Descriptions.Item label="Status">{userData.status}</Descriptions.Item>
                      <Descriptions.Item label="Department">{userData.department}</Descriptions.Item>
                    </Descriptions>,
                    icon: <UserOutlined style={{ color: '#108ee9' }} />,
                    style: {
                      width: 600,
                    },
                  });
                }}>Profile</Menu.Item>
                <Menu.Item key="logout" onClick={logout}>Logout</Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        }
      </Row>
    </Header>
  );
}

export default Home;
