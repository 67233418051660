import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  InputNumber,
  List,
  message,
  Space,
  Typography,
  Upload,
} from 'antd';
import axios from 'axios';
import { DeleteOutlined, UploadOutlined, } from '@ant-design/icons';
import moment from 'moment';
import { formatNumberEuro } from '../../utils';

export default function ContractInvoiceFormDrawer({ invoice, contract, visible, onClose }) {
  if (!contract) return null;
  const [form] = Form.useForm();
  const [invoices, setInvoices] = useState([]);
  const inputRef = useRef();
  const value = Form.useWatch('value', form);
  const valueRON = Form.useWatch('valueRON', form);

  const exchangeRate = useMemo(
    () => !value ? 0 : (valueRON || 0)/value,
    [value, valueRON]
  );

  const totalInvoicesValue = useMemo(
    () =>{
      if (contract.invoices?.totalInvoicesValue !== undefined) return contract.invoices?.totalInvoicesValue;
      if (invoice && Array.isArray(invoices) && invoices.length) {
        return invoices.reduce( ( sum, { value, _id } ) => {
          if (invoice._id !== _id) return sum + value;
          return sum;
        } , 0);
      }
      return 0;
    },
    [invoice, contract, invoices]
  );

  const maxInvoiceValue = useMemo(
    () => (contract?.feeValue || 0) - (totalInvoicesValue || 0),
    [totalInvoicesValue, contract]
  );

  const fetchInvoices = useCallback(
    async () => {
      try {
        const result = await axios(`/api/contracts/${contract._id}/invoices`);
        setInvoices(result.data);
      } catch (e) {
        console.log(e);
      }
    },
    [contract],
  );

  useEffect(() => {
    if (visible && contract._id) fetchInvoices();
    if (visible && invoice) {
      form.setFieldsValue({
        date: moment(invoice.date),
        value: invoice.value,
        valueRON: invoice.valueRON,
      });
    }
  }, [invoice, fetchInvoices, contract, visible, inputRef]);

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef?.current?.focus();
    },
    [inputRef],
  );

  const close = () => {
    form.resetFields();
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      const data = new FormData();
      const file = values.attachment?.file;
      if (file) {
        data.append("attachment", values.attachment.file.originFileObj);
        data.append("attachmentFileName", values.attachment.file.name);
      }
      data.append("contract", contract._id);
      data.append("date", values.date);
      data.append("value", values.value);
      if (values.valueRON) data.append("valueRON", values.valueRON);
      await axios({
        method: invoice ? 'PUT' : 'POST',
        url: `/api/invoices/${invoice?._id ?? ''}`,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success('Invoice saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  return (
    <>
      <Drawer
        width={640}
        title={`${invoice ? 'Edit' : 'Add'} Invoice ${invoice ? invoice.invoice_number : ''} - Contract ${contract?.contract_number}`}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Form
          form={form}
          layout="vertical"
          size="default"
          name="contract-form"
          initialValues={invoice ? null : {
            value: maxInvoiceValue,
          }}
        >
          <Typography.Paragraph>
            Already invoiced (not including current): {formatNumberEuro(totalInvoicesValue)} of {formatNumberEuro(contract.feeValue)}.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <strong>{formatNumberEuro(maxInvoiceValue)}</strong> left!
          </Typography.Paragraph>
          <Divider />
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true, message: 'Please input the date!' }]}
          >
            <DatePicker ref={inputRef}/>
          </Form.Item>
          <Form.Item
            label="Value"
            name="value"
            rules={[{ required: true, message: 'Please input a value!' }]}
          >
            <InputNumber
              addonBefore="EUR"
              max={maxInvoiceValue}
              placeholder={`Max ${maxInvoiceValue}`}
            />
          </Form.Item>
          <Form.Item label="Value" name="valueRON">
            <InputNumber addonBefore="RON" />
          </Form.Item>
          <Typography><pre>Exchange rate: 1 EUR = <strong>{exchangeRate.toFixed(2)}</strong> RON</pre></Typography>
          <Form.Item label="File attachment (PDF only)" name="attachment">
            <Upload
              customRequest={() => true}
              showUploadList={true}
              maxCount={1}
              accept="application/pdf"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Form>
        <Divider />
        <List
          header={<Typography.Title level={5}>{`Existing invoices for contract ${contract?.contract_number}`}</Typography.Title>}
          itemLayout="horizontal"
          dataSource={invoices}
          renderItem={item => (
            <List.Item
              actions={[<Button shape="circle" icon={<DeleteOutlined />} key={`${item._id}delete`} onClick={async () => {
                await axios.delete(`/api/invoices/${item._id}`);
                await fetchInvoices();
              }} />]}
            >
              <List.Item.Meta
                title={`#${item.invoice_number} - ${moment(item.date).format("DD/MM/YYYY")} - ${item.value||''}`}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
}
