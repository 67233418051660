import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Form, Input, Button, message, Select, Space, Drawer, InputNumber, DatePicker, Divider, Typography, Row, Col,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formatNumberEuro } from '../../utils';

const { Option } = Select;
const { TextArea } = Input;

const getTotalPercentage = (referrals) => referrals?.reduce((sum, current) => sum + current?.percentage, 0) || 0;

export default function TransactionFormDrawer({ id, visible, onClose }) {
  const [form] = Form.useForm();
  const inputRef = useRef();
  const [agents, setAgents] = useState([]);
  const [projects, setProjects] = useState([]);
  const buyerFee = Form.useWatch('buyerFee', form);
  const sellerFee = Form.useWatch('sellerFee', form);
  const referrals = Form.useWatch('referrals', form);

  useEffect(() => {
    if (!visible) return;
    const fetchTransaction = async () => {
      try {
        const result = await axios(`/api/transactions/${id}`);
        const transaction = result.data;
        transaction.date = moment(transaction.date);
        form.setFieldsValue(transaction);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchAgents = async () => {
      try {
        const result = await axios('/api/agents');
        setAgents(result.data);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchProjects = async () => {
      try {
        const result = await axios('/api/projects');
        setProjects(result.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAgents();
    fetchProjects();
    if (id) fetchTransaction();
  }, [id, inputRef, visible]);

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef.current.focus();
    },
    [inputRef],
  );

  const close = () => {
    form.resetFields();
    onClose();
  }

  const totalFee = useMemo(
    () => (buyerFee || 0) + (sellerFee || 0),
    [buyerFee, sellerFee]
  );

  const netFee = useMemo(
    () => {
      const totalReferrals = getTotalPercentage(referrals);
      return (100 - totalReferrals) * totalFee / 100;
    },
    [referrals, totalFee]
  );

  const save = async () => {
    try {
      const values = await form.validateFields();
      const totalAgentsPrc = getTotalPercentage(values.agentFees);
      if (totalAgentsPrc !== 100) {
        message.error("AGENTS FEES should be 100% !!!");
        return;
      }
      if (values.sellers?.replace) values.sellers = values.sellers.replace(/\s/g, '').split(',');
      await axios({
        method: id ? 'PUT' : 'POST',
        url: `/api/transactions/${id ?? ''}`,
        data: values,
      });
      message.success('Transaction saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  const onValuesChange = async (changedValues, allValues) => {
    const valuesToSet = { ...allValues };
    if(changedValues.hasOwnProperty("buyerFeePercentage")) {
      if (valuesToSet.value) {
        valuesToSet.buyerFee = (valuesToSet.value * changedValues.buyerFeePercentage) / 100;
        form.setFieldsValue(valuesToSet);
      }
    }
    if(changedValues.hasOwnProperty("sellerFeePercentage")) {
      if (valuesToSet.value) {
        valuesToSet.sellerFee = (valuesToSet.value * changedValues.sellerFeePercentage) / 100;
        form.setFieldsValue(valuesToSet);
      }
    }
    if(changedValues.hasOwnProperty("value")) {
      if (valuesToSet.buyerFeePercentage) {
        valuesToSet.buyerFee = (changedValues.value * valuesToSet.buyerFeePercentage) / 100;
      }
      if (valuesToSet.sellerFeePercentage) {
        valuesToSet.sellerFee = (changedValues.value * valuesToSet.sellerFeePercentage) / 100;
      }
      form.setFieldsValue(valuesToSet);
    }
    if(changedValues.hasOwnProperty("referrals")) {
      valuesToSet.referrals = valuesToSet.referrals.map((ref) => {
        if (ref?.percentage && totalFee) ref.value = (ref.percentage * totalFee) / 100;
        return ref;
      })
      form.setFieldsValue(valuesToSet);
    }
    if(changedValues.hasOwnProperty("agentFees")) {
      valuesToSet.agentFees = valuesToSet.agentFees.map((ref) => {
        if (ref?.percentage && totalFee) ref.value = (ref.percentage * netFee) / 100;
        return ref;
      })
      form.setFieldsValue(valuesToSet);
    }
    if(changedValues.hasOwnProperty("project")) {
      const chosen = projects.find(p => p._id === changedValues.project);
      form.setFieldsValue({ ...valuesToSet, ...chosen });
    }
  };

  return (
    <>
      <Drawer
        width={800}
        title={id ? "Edit Transaction" : "Add Transaction"}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Form
          form={form} layout="vertical" size="default" name="group-form"
          onValuesChange={onValuesChange}
          initialValues={{ buyerFee: 0, sellerFee: 0, buyerFeePercentage: 0, sellerFeePercentage: 0 }}
        >
          <Form.Item label="Project" name="project">
            <Select style={{ width: '100%' }}>
              <Select.Option value="" />
              {
                projects.map((obj) => <Select.Option value={obj._id} key={obj._id}>{obj.client}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Row>
            <Col span={8}>
              <Form.Item label="Date" name="date" rules={[{ required: true }]}>
                <DatePicker ref={inputRef}/>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label="Property" name="imobil" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16,0]}>
            <Col span={12}>
              <Form.Item label="Department" name="department" rules={[{ required: true }]}>
                <Select>
                  <Option value="rezi">rezi</Option>
                  <Option value="office">office</Option>
                  <Option value="industrial">industrial</Option>
                  <Option value="land">land</Option>
                  <Option value="PM">PM</Option>
                  <Option value="research">research</Option>
                  <Option value="investment">investment</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                <Select>
                  <Option value="sell side">sell side</Option>
                  <Option value="buy side">buy side</Option>
                  <Option value="both">both</Option>
                  <Option value="lease-landlord">lease-landlord</Option>
                  <Option value="lease-tenant">lease-tenant</Option>
                  <Option value="lease both">lease both</Option>
                  <Option value="advisory">advisory</Option>
                  <Option value="PM">PM</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16,0]}>
            <Col span={12}>
              <Form.Item label="Buyer" name="buyer">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Seller" name="sellers">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Comment" name="comment">
            <TextArea rows={2} placeholder="Max. 512 chars" maxLength={512} />
          </Form.Item>
          <Divider />
          <Form.Item label="Total value" name="value" rules={[{ required: true }]}>
            <InputNumber addonBefore="EUR" />
          </Form.Item>
          <Form.Item label="Buyer Fee" style={{ marginBottom: 0 }}>
            <Input.Group compact>
              <Form.Item name="buyerFeePercentage">
                <InputNumber addonBefore="%" />
              </Form.Item>
              <span
                style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}
              > OR </span>
              <Form.Item name="buyerFee">
                <InputNumber addonBefore="EUR" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="Seller Fee" style={{ marginBottom: 0 }}>
            <Input.Group compact>
              <Form.Item name="sellerFeePercentage">
                <InputNumber addonBefore="%" />
              </Form.Item>
              <span
                style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}
              > OR </span>
              <Form.Item name="sellerFee">
                <InputNumber addonBefore="EUR" />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Typography><pre>TOTAL FEE: {formatNumberEuro(totalFee)}</pre></Typography>
          <Typography><pre>NET FEE: {formatNumberEuro(netFee)}</pre></Typography>
          <Divider />
          <Typography.Title level={4}>Referrals</Typography.Title>
          <Form.List name="referrals">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="middle">
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing referral name' }]}
                    >
                      <Input style={{ width: 200 }}/>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'percentage']}
                      rules={[{ required: true, message: 'Missing referral percentage' }]}
                    >
                      <InputNumber addonBefore="%" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                    >
                      <InputNumber addonBefore="EUR" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add referral
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Typography.Title level={4}>Agents (should equal 100% in total!)</Typography.Title>
          <Form.List name="agentFees">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="middle">
                    <Form.Item
                      {...restField}
                      name={[name, 'agent']}
                      rules={[{ required: true, message: 'Missing agent' }]}
                    >
                      <Select style={{ width: 200 }}>
                        <Select.Option value="" />
                        {
                          agents.map((obj) => <Select.Option value={obj._id} key={obj._id}>{obj.name}</Select.Option>)
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'percentage']}
                      rules={[{ required: true, message: 'Missing agent percentage' }]}
                    >
                      <InputNumber addonBefore="%" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                    >
                      <InputNumber addonBefore="EUR" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add agent
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
}
