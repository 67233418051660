import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const context = createContext(null);

export default function UserProvider({ children }) {
  // The useState() hook defines a state variable.
  const [userData, setUserData] = useState(null);
  const [initDone, setInitDone] = useState(false);

  const getMe = async () => {
    let user;
    try {
      user = await axios.get('/api/users/me');
      setUserData(user.data);
    } catch (err) {
      setUserData(null);
    }
    finally {
      setInitDone(true);
    }
    return user?.data;
  }

  // The useEffect() hook registers a function to run after render.
  useEffect(() => {
    getMe();
  }, []);  // This empty array means the effect will only run once.

  // On the first render userData will have the default value null.
  // But after that render, the effect function will run and will
  // start a fetch of the real user data. When the data arrives, it
  // will be passed to setUserData(), which changes state and
  // triggers a new render. On this second render, we'll have real
  // user data to provide to any consumers. (And the effect will not
  // run again.)
  return (
    <context.Provider value={{ userData, setUserData, initDone, getMe }}>
      {children}
    </context.Provider>
  );
}
UserProvider.context = context;
