import React, { Component } from 'react';
import axios from 'axios';
import { Alert, Breadcrumb, Button, Input, message, PageHeader, Space, Table, Tag, Tooltip, } from 'antd';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  EuroCircleOutlined,
  FileAddOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import moment from 'moment';
import withRouter from '../../Routes/withRouter';
import { formatNumberEuro } from '../../utils';

class ProjectContractList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      project: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => {
      return Object.keys(o).some((k) => {
        return String(o[k]).toLowerCase().includes(value.toLowerCase())
          // search in the property as well
          || String(o[k]?.imobil).toLowerCase().includes(value.toLowerCase())
      });
    });

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { id } = this.props.params;
    try {
      const invoices = await axios(`/api/projects/${id}/invoices/`);
      const project = await axios(`/api/projects/${id}`);
      this.setState({ baseData: invoices.data, project: project.data });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  render() {
    const {
      error, baseData, project, filteredData, selectedRowKeys
    } = this.state;
    if (!baseData || !project) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Code',
        dataIndex: '_id',
        key: 'code',
        sorter: (a, b) => a._id.localeCompare(b._id, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        width: 160,
        responsive: ['md','lg', 'xl', 'xxl']
      },
      {
        title: 'Value',
        dataIndex: 'totalValue',
        key: 'totalValue',
        render: (text, record) => formatNumberEuro(record.totalValue),
        sorter: (a, b) => a.totalValue - b.totalValue,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 300,
        align: 'right'
      },
      {
        title: 'Cashed',
        key: 'cashed',
        render: (text, record) => {
          const cashed = (record.totalValue === record.totalCashed);
          return <Tag color={cashed ? 'green' : 'volcano'}>
            {cashed ? 'YES' : 'NO'}
          </Tag>
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'center'
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Cash invoice">
              <Button
                shape="circle" key="cashInvoice" icon={<EuroCircleOutlined />}
                onClick={async () => {
                  try {
                    await axios(`/api/invoices/${record._id}/cash`);
                    message.success('Invoice cashed!');
                    await this.loadData();
                  }
                  catch (e) {
                    // console.log(e);
                    message.error(e);
                  }
                }}
                disabled={record.totalValue === record.totalCashed}
              />
            </Tooltip>
          </Space>
        ),
        responsive: ['lg', 'xl', 'xxl'],
        width: 120,
        align: 'center'
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/projects">
              <ApartmentOutlined />
              {' '}
              Project list
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <AuditOutlined />
            {' '}
            Contract list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title={`Invoice List for project: ${project.client}`}
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} contract(s) selected`}</Tag> : null),
            <Button
              key="1"
              type="primary"
              icon={<FileAddOutlined />}
              disabled={!selectedRowKeys?.length}
              onClick={() => {
                this.setState({ invoiceModalVisible: true });
              }}
            >
              Add Invoices
            </Button>,
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"

          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
      </>
    );
  }
}

export default withRouter(ProjectContractList);
