import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Drawer, Form, InputNumber, message, Space, } from 'antd';
import axios from 'axios';

export default function CashInvoiceFormDrawer({ invoice, visible, onClose }) {
  if (!invoice) return null;
  const [form] = Form.useForm();
  const inputRef = useRef();

  const setFocus = useCallback(
    (isOpen) => {
      isOpen && inputRef?.current?.focus();
    },
    [inputRef],
  );

  const max = useMemo(
    () => {
      const maxx = invoice?.value ? (invoice.value - invoice.cashedAmount) : Number.MAX_SAFE_INTEGER;
      form.setFieldsValue({ toBeCashed: maxx});
      return maxx;
    },
    [invoice, form]
  );

  const close = () => {
    form.resetFields();
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      await axios({
        method: 'PUT',
        url: `/api/invoices/${invoice._id}`,
        data: { cashedAmount: (invoice.cashedAmount + values.toBeCashed) },
      });
      message.success('Cashed amount saved!');
      form.resetFields();
      close();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  return (
    <>
      <Drawer
        width={640}
        title={`Add amount to be cashed for Invoice ${invoice?.invoice_number}`}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
        extra={
          <Space>
            <Button onClick={close}>Cancel</Button>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </Space>
        }
        afterVisibleChange={setFocus}
      >
        <Form form={form} layout="vertical" size="default" name="contract-form">
          <Form.Item
            label="Value to be cashed"
            name="toBeCashed"
            rules={[{ required: true, message: 'Please input a value!' }]}
          >
            <InputNumber
              addonBefore="EUR"
              max={max}
              placeholder={`Max ${max}`}
              ref={inputRef}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
