import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import UserProvider from './context/UserContext';
import axios from 'axios';
axios.defaults.withCredentials = true;

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <UserProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </UserProvider>,
  document.getElementById('root'),
);
// registerServiceWorker();
