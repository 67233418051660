import React from 'react';
import { Space, Spin } from 'antd';

export default function LoadingFeedback() {
  return (
    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginTop: '100px' }}>
      <Spin size="large" />
    </Space>
  );
}
