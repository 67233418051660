import React, { Component } from 'react';
import axios from 'axios';
import {
  Table,
  Alert,
  Space,
  Button,
  PageHeader,
  Tag,
  Breadcrumb, Input, Tooltip, Popconfirm,
} from 'antd';
import {
  AppstoreOutlined, DeleteOutlined, FileAddOutlined, SwapOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LoadingFeedback from '../LoadingFeedback';
import moment from 'moment';
import TransactionFormDrawer from './TransactionFormDrawer';
import TransactionContractFormDrawer from './TransactionContractFormDrawer';
import UserProvider from '../../context/UserContext';

export default class TransactionList extends Component {
  static contextType = UserProvider.context;

  constructor(props) {
    super(props);

    this.state = {
      baseData: null,
      filteredData: null,
      error: '',
      selectedRowKeys: [],
      modalVisible: false,
      idToEdit: null,
      transactionForContract: null,
      contractModalVisible: false,
    };
  }

  search = (value) => {
    const { baseData } = this.state;

    const filteredData = baseData.filter((o) => Object.keys(o).some((k) => String(o[k])
      .toLowerCase()
      .includes(value.toLowerCase())
        // search in the away group's name as well
        || String(o[k]?.name)
          .toLowerCase()
          .includes(value.toLowerCase())));

    this.setState({ filteredData });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    try {
      const transactions = await axios('/api/transactions/');
      const baseData = transactions.data;
      this.setState({ baseData });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  removeObject = async (id) => {
    try {
      await axios.delete(`/api/transactions/${id}`);
      await this.loadData();
    } catch (err) {
      this.setState({ error: err.message });
    }
  };

  render() {
    const {
      error, baseData, filteredData, selectedRowKeys, modalVisible, idToEdit, transactionForContract, contractModalVisible
    } = this.state;
    if (!baseData) return <LoadingFeedback />;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: 'Number',
        dataIndex: 'transaction_number',
        key: 'transaction_number',
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              this.setState({idToEdit: record._id, modalVisible: true});
            }}
          >
            {record.transaction_number}
          </Button>
        ),
        sorter: (a, b) => a.transaction_number - b.transaction_number,
        width: 100,
        fixed: 'left',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        fixed: 'left',
      },
      {
        title: 'Project',
        key: 'project',
        render: (text, record) => record.project?.client,
        sorter: (a, b) => a.project?.client.localeCompare(b.project?.client, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 200,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        key: 'department',
        sorter: (a, b) => a.department.localeCompare(b.department, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 160,
      },
      {
        title: 'Property',
        dataIndex: 'imobil',
        key: 'imobil',
        sorter: (a, b) => a.imobil.localeCompare(b.imobil, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 300,
      },
      {
        title: 'Buyer',
        dataIndex: 'buyer',
        key: 'buyer',
        sorter: (a, b) => a.buyer.localeCompare(b.buyer, undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 200,
      },
      {
        title: 'Seller',
        dataIndex: 'sellers',
        key: 'sellers',
        render: (text, record) => record.sellers.join(", "),
        sorter: (a, b) => a.sellers.join('').localeCompare(b.sellers.join(''), undefined, { sensitivity: 'base' }),
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 200,
      },
      {
        title: 'Total Value',
        dataIndex: 'value',
        key: 'value',
        render: (text, record) => `€${record.value.toLocaleString('en-US')}`,
        sorter: (a, b) => a.value - b.value,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: 'Total Fee',
        key: 'fee',
        render: (text, record) => `€${record.totalFee?.toLocaleString('en-US') || 0}`,
        sorter: (a, b) => a.totalFee - b.totalFee,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 120,
        align: 'right'
      },
      {
        title: '% Contr.',
        key: 'prccontr',
        render: (text, record) => {
          const { totalFee } = record;
          const totalContractsFee = record.contracts?.totalFee || 0;
          if (!totalFee || !totalContractsFee) return '';
          return `${(100 * totalContractsFee/totalFee).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right',
        fixed: 'right',
      },
      {
        title: '% Inv.',
        key: 'prcinv',
        render: (text, record) => {
          const { totalFee } = record;
          const totalInvoicesFee = record.contracts?.totalInvoiced || 0;
          if (!totalFee || !totalInvoicesFee) return '';
          return `${(100 * totalInvoicesFee/totalFee).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right',
        fixed: 'right',
      },
      {
        title: '% Cashed',
        key: 'prccash',
        render: (text, record) => {
          const { totalFee } = record;
          const totalInvoicesFee = record.contracts?.totalCashed || 0;
          if (!totalFee || !totalInvoicesFee) return '';
          return `${(100 * totalInvoicesFee/totalFee).toLocaleString('en-US')}%`;
        },
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'right',
        fixed: 'right',
      },
      {
        title: 'Closed',
        dataIndex: 'closed',
        key: 'status',
        render: (text, record) => {
          return <Tag color={record.closed ? 'volcano' : 'green'}>
            {record.closed ? 'YES' : 'NO'}
          </Tag>
        },
        filters: [
          {
            text: 'No',
            value: false,
          },
          {
            text: 'Yes',
            value: true,
          },
        ],
        filterSearch: false,
        onFilter: (value, record) => record.closed === value,
        responsive: ['md','lg', 'xl', 'xxl'],
        width: 100,
        align: 'center',
        fixed: 'right',
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => (
          <Space size="middle">
            <Tooltip title="Add contract">
              <Button
                shape="circle" key="addContract" icon={<FileAddOutlined />}
                onClick={() => {
                  this.setState({transactionForContract: record, contractModalVisible: true});
                }}
                disabled={record.contracts?.totalFee === record.totalFee}
              />
            </Tooltip>
            {
              this.context?.userData?.level === 2 && (
                <Popconfirm
                  title="Are you sure to delete this transaction?"
                  onConfirm={() => this.removeObject(record._id)}
                  onCancel={null}
                  okText="YES"
                  cancelText="No"
                  placement="left"
                  disabled={!!record.contracts}
                >
                  <Tooltip title="Delete transaction">
                    <Button
                      disabled={!!record.contracts}
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )
            }
          </Space>
        ),
        responsive: ['lg', 'xl', 'xxl'],
        width: 120,
        align: 'center',
        fixed: 'right',
      },
    ];
    const tableData = (filteredData || baseData).map((item) => ({ key: item._id, ...item }));
    return (
      <>
        {error ? <Alert message={error} type="error" /> : null}
        <Breadcrumb style={{ padding: '16px 24px 0' }}>
          <Breadcrumb.Item>
            <Link to="/">
              <AppstoreOutlined />
              {' '}
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <SwapOutlined />
            {' '}
            Transaction list
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          title="Transaction List"
          tags={(
            <Tag>
              {tableData.length}
              {' '}
              item(s)
            </Tag>
          )}
          className="site-page-header"
          extra={[
            (hasSelected ? <Tag>{`${selectedRowKeys.length} transaction(s) selected`}</Tag> : null),
            <Button
              key="1"
              type="primary"
              icon={<FileAddOutlined />}
              onClick={() => {
                this.setState({ modalVisible: true });
              }}
            >
              Add Transaction
            </Button>,
          ]}
        />
        <Input.Search
          style={{ marginBottom: '16px' }}
          placeholder="Search by..."
          enterButton
          onSearch={this.search}
        />
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          size="middle"
          scroll={{
            x: 2000,
          }}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `Total: ${total} items`,
          }}
        />
        <TransactionFormDrawer
          id={idToEdit}
          onClose={async () => {
            this.setState({modalVisible: false, idToEdit: null});
            await this.loadData();
          }}
          visible={modalVisible}
        />
        <TransactionContractFormDrawer
          transaction={transactionForContract}
          onClose={async () => {
            this.setState({contractModalVisible: false, transactionForContract: null});
            await this.loadData();
          }}
          visible={contractModalVisible}
        />
      </>
    );
  }
}
