import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  InputNumber,
  message,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import axios from 'axios';
import { FileAddOutlined, FilePdfOutlined, UploadOutlined, } from '@ant-design/icons';
import moment from 'moment/moment';
import { formatNumberEuro } from '../../utils';

export default function AgentInvoicesFormDrawer({ agent, visible, onClose }) {
  if (!agent) return null;
  const [form] = Form.useForm();
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const inputRef = useRef();

  const fetchTransactions = useCallback(async () => {
    try {
      const trs = await axios(`/api/agents/${agent._id}/transactions`);
      const inv = await axios(`/api/agents/${agent._id}/invoices`);
      const merged = trs.data.map((t) => {
        t.invoices = inv.data.filter((i) => i.transaction === t._id);
        return t;
      })
      setTransactions(merged);
    } catch (e) {
      console.log(e);
    }
  }, [agent]);

  useEffect(() => {
    if (visible && agent._id) fetchTransactions();
  }, [visible, agent, fetchTransactions]);

  const close = () => {
    form.resetFields();
    onClose();
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      const data = new FormData();
      const file = values.attachment?.file;
      if (file) {
        data.append("attachment", values.attachment.file.originFileObj);
        data.append("attachmentFileName", values.attachment.file.name);
      }
      data.append("transaction", transaction._id);
      data.append("agent", agent._id);
      data.append("date", values.date);
      data.append("valueEUR", values.valueEUR);
      if (values.valueRON) data.append("valueRON", values.valueRON);
      await axios({
        method: 'POST',
        url: `/api/agentinvoices`,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success('Invoice added for agent/transaction!');
      form.resetFields();
      setTransaction(null);
      await fetchTransactions();
    } catch (err) {
      if (!err.errorFields) message.error(err.message);
    }
  };

  const max = useMemo(
    () => {
      if (!transaction) return 0;
      return transaction.totalFee - transaction.invoices?.reduce((n, {valueEUR}) => n + valueEUR || 0, 0);
    },
    [transaction]
  );
  // const max = (agent?.earned || 0) - (agent?.billed || 0);
  return (
    <>
      <Drawer
        width={800}
        title={`Transactions for ${agent?.name}`}
        placement="right"
        onClose={close}
        visible={visible}
        bodyStyle={{ paddingBottom: '80px' }}
      >
        { transaction &&
          <>
            <Typography.Title level={5}>
              {`Add invoice for transaction #${transaction?.transaction_number} / ${moment(transaction.date).format("DD-MM-YYYY")}`}
            </Typography.Title>
            <Form form={form} layout="vertical" size="default" name="invoice-form">
              <Form.Item label="Date" name="date" rules={[{ required: true }]}>
                <DatePicker ref={inputRef} />
              </Form.Item>
              <Form.Item label="Amount EUR" name="valueEUR" rules={[{ required: true }]}>
                <InputNumber addonBefore="EUR" max={max} placeholder={`Max ${max}`}/>
              </Form.Item>
              <Form.Item label="Amount RON" name="valueRON">
                <InputNumber addonBefore="RON" />
              </Form.Item>
              <Form.Item label="File attachment (PDF only)" name="attachment">
                <Upload
                  customRequest={() => true}
                  showUploadList={true}
                  maxCount={1}
                  accept="application/pdf"
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button htmlType="button" onClick={() => setTransaction(null)}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="button" onClick={save}>
                    ADD
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            <Divider />
            <Typography.Title level={5}>{`Existing transactions for agent ${agent?.name}`}</Typography.Title>
          </>
        }
        <Table
          columns={[
            {
              title: 'Transaction',
              dataIndex: 'transaction_number',
              key: 'transaction_number',
              sorter: (a, b) => a.transaction_number - b.transaction_number,
              width: 50
            },
            {
              title: 'Property',
              dataIndex: 'imobil',
              key: 'imobil',
              sorter: (a, b) => a.imobil.localeCompare(b.imobil, undefined, { sensitivity: 'base' }),
              responsive: ['md','lg', 'xl', 'xxl']
            },
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
              render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
              sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
              responsive: ['md','lg', 'xl', 'xxl'],
              width: 120,
            },
            {
              title: 'Invoices',
              key: 'invoices',
              render: (text, record) => record.invoices.length || 0,
              sorter: (a, b) => (a.invoices?.length || 0) - (b.invoices?.length || 0),
              responsive: ['md','lg', 'xl', 'xxl']
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (text, record) => (
                <Tooltip title="Add invoice">
                  <Button
                    icon={<FileAddOutlined />}
                    key={`${record._id}add`}
                    onClick={async () => {
                      form.resetFields();
                      setTransaction(record);
                    }}
                    // disabled={!agent.earned}
                    shape="circle"
                  />
                </Tooltip>
              ),
              width: 100,
              align: "center"
            },
          ]}
          dataSource={transactions}
          size="middle"
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          expandable={{
            expandedRowRender: record => (
              <Table
                size="small"
                bordered
                footer={() => '==='}
                columns={[
                  {
                    title: 'Invoice',
                    dataIndex: 'agent_invoice_number',
                    key: 'agent_invoice_number',
                    sorter: (a, b) => a.agent_invoice_number - b.agent_invoice_number,
                    width: 50
                  },
                  {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    render: (text, record) => moment(record.date).format("DD/MM/YYYY"),
                    sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
                    responsive: ['md','lg', 'xl', 'xxl'],
                    width: 120,
                  },
                  {
                    title: 'Value',
                    dataIndex: 'valueEUR',
                    render: (text, record) => formatNumberEuro(record.valueEUR),
                    key: 'valueEUR',
                    sorter: (a, b) => a.valueEUR - b.valueEUR,
                    width: 120,
                    align: 'right'
                  },
                  {
                    title: 'Att',
                    key: 'attachment',
                    render: (text, record) => (
                      <Tooltip title="See attachment">
                        <Button
                          disabled={!record.attachmentFileName}
                          shape="circle" size="small"
                          href={`/uploads/${record.attachmentFileName}`}
                          icon={<FilePdfOutlined />}
                          target="_blank"
                        />
                      </Tooltip>
                    ),
                    width: 100,
                    align: "center"
                  },
                ]}
                dataSource={record.invoices || []}
                pagination={false}
              />
            ),
            rowExpandable: record => record.invoices?.length > 0,
          }}
        />
      </Drawer>
    </>
  );
}
